// import logo from './logo.svg';
import { FC, useState, useEffect } from "react";
import adminServices from "../../../services/adminServices";
import { useNavigate, useParams } from "react-router-dom";
import './FormB.css'
import Title from './FormBTitle';
import Footer from './FormBFooter';
import Notes from './FormBNotes';
import FormData from './FormBData';
import { BidDisplay, TrustFund } from "../../../services/dtos";
import bidServices from "../../../services/bidServices";


function FormB() {

  let navigate = useNavigate();
  const { bidId } = useParams()


  const [trustFund, settrustFund] = useState<TrustFund>(new TrustFund());

  const [bid, setBid] = useState<BidDisplay>(new BidDisplay());


  useEffect(() => {


    let asvc = new adminServices();
    let bsvc = new bidServices();


    asvc.getTrustFund().then((TrustFund) => {
      document.title = TrustFund.trust_name;

      settrustFund(TrustFund);

    });

    bsvc.getBids(parseInt(bidId)).then((bid) => {
      if (bid && bid.length > 0 && bid[0].bid_status.toLocaleLowerCase()!="successful")
      {
        setBid(bid[0]);
      
      }
      else
      {
        navigate("/");
      }
    });


  }, []);

  return (
    <div className="container">
      <Title trust={trustFund}></Title>
      <FormData bid={bid} />
      <Notes trust={trustFund}  bid={bid} />
      <Footer trust={trustFund} bid={bid}></Footer>
    </div>
  );
}

export default FormB;
