import React from "react";
import './footer.css';
import sig from "./jimsig.png";

import { ReactNode } from "react";
import { BidDisplay, TrustFund } from "../../../services/dtos";


interface Props {
    bid?: BidDisplay,
    trust?: TrustFund
    children?: ReactNode
    // any props that come into the component
}

const FormBFooter = ({ children, ...props }: Props) => {

    return(
        <div id="footer-wrapper">
            <div id="footer-1">
                <div style={{display:"flex", flexDirection:"row", paddingBottom:'20px'}}>
                    <div style={{flex:1}}>
                        <span className="field">By: <img width="180" src={sig} alt="Signature" /></span>
                     
                        <span className="value"> James R Frees</span>
                 
                    </div>
                    <div style={{flex:1}}>
                        <span className="field">Date:</span>
                        <span className="value"> {props.bid?.approved_won_formc_date}</span>
                    </div>
                </div>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <div style={{flex:1}}>
                        <span className="field">Phone:</span>
                        <span className="value"> {props.trust.contact_phone}</span>
                    </div>
                    <div style={{flex:1}}>
                        <span className="field">Email:</span>
                        <span className="valueUL"> {props.trust.email_address}</span>
                    </div>
                </div>
            </div>
            
           
            <div id="footer-2">
                <div>
                    <span> </span>
                </div>
                <div>
                    <span className="field">TRUST PROJECT REF.#</span>
                    <span className="value" style={{fontWeight:"900"}}>{props.bid.project_Reference_Number}</span>
                </div>
            </div>
        </div>
        
    )
}

export default FormBFooter;