import React from "react";
import { ReactNode } from "react";
import './notes.css';
import { TrustFund } from "../../../services/dtos";
import { BidDisplay } from "../../../services/dtos";

interface Props {
    bid?: BidDisplay,
    trust?: TrustFund
    children?: ReactNode
    // any props that come into the component
}

const FormBNotes = ({ children, ...props }: Props) => {
    return(
        <div id="notes-wrapper">
            <h2 id="title">NOTE THE FOLLOWING:</h2>
            <div id="text">
                <ol>
                    <li>To receive Trust funds, ALL duct work must be fabricated by {props.trust.local_name} members</li>
                
                    <li>All subsidy payments previously awarded to any employer which have not yet been billed to or paid by
                        the Trust, shall be suspended upon the Trust receiving notification of repudiation or attempted repudiation
                        of the Collective Bargaining Agreement, or of the filing of any decertification petition or the pendency of any
                        decertification election, until such decertification or repudiation is resolved. In the event a decertification
                        election is successful or the repudiation is successful, all suspended payments shall be deemed forfeited and
                        be made available to the Trust. In the event the decertification petition is terminated or the decertification
                        election unsuccessful or the employer returns to full compliance with the Collective Bargaining Agreement, all
                        suspended payments shall be made to the employer upon compliance with other Trust policies.
                    </li>
                    <li>Decisions of this office may be appealed to the Board of Trustees. All appeals must be submitted to the
Trust Office in writing and will be reviewed by the Trustees at the next regularly scheduled Trust Board
Meeting. ALL DECISIONS OF THE BOARD OF TRUSTEES ARE FINAL AND BINDING ON ALL PARTIES.</li>
                </ol>
            </div>
        </div>
    )
}

export default FormBNotes;