import type { FC } from 'react';

import { useState, useEffect, useRef } from 'react';

import { Button, Toast, Table, Tooltip, Select, Checkbox, Modal, ToggleSwitch, Badge } from '../../lib';
import { HiInformationCircle, HiCheck } from 'react-icons/hi';
import { Alert } from '../../lib';
import { useNavigate, useParams } from "react-router-dom";
//import Datepicker from "flowbite-datepicker/Datepicker";
import { Spinner } from '../../lib';
import { setDefaultResultOrder } from 'dns/promises';
import bidServices from '../../services/bidServices';
import apiServices from '../../services/apiServices';
import { MdHdrStrong } from 'react-icons/md';
const SubmitBidFormAPage: FC = () => {

  const [Decline_Reason, setDecline_Reason] = useState<string>("");
  const [Hours_Granted, setHours_Granted] = useState<number>(null);
  const [Hours_Rate, setHours_Rate] = useState<number>(0);

  const [Project_Name, setProject_Name] = useState<string>("");

  const [Project_Address, setProject_Address] = useState<string>("");

  const [Project_City, setProject_City] = useState<string>("");

  const [Project_County, setProject_County] = useState<string>("");

  const [Project_Type, setProject_Type] = useState<string>("Architectural");

  const [Hours_Requested, setHours_Requested] = useState<number>(0);

  const [Bid_Date, setBid_Date] = useState<string>("");
  const [Bid_Time, setBid_Time] = useState<string>("");

  const [Shop_Hours, setShop_Hours] = useState<number>(0);
  const [Field_Hours, setField_Hours] = useState<number>(0);
  const [Fab_Contractor, setFab_Contractor] = useState<string>("");

  const [Engineers, setEngineers] = useState<string>("");

  const [Equipment_Suppliers, setEquipment_Suppliers] = useState<string>("");
  const [Competition, setCompetition] = useState<string>("");
  const [Competition_Reason, setCompetition_Reason] = useState<string>("");

  const [Contact, setContact] = useState<string>(localStorage.getItem("FormAContact"));
  const [contact_email, setcontact_email] = useState<string>(localStorage.getItem("FormAcontact_email"));

  const [contact_phone, setcontact_phone] = useState<string>(localStorage.getItem("FormAcontact_phone"));

  const [prevailing_wage, setprevailing_wage] = useState<boolean>(false);
  const [less_than_10k_ft2, setless_than_10k_ft2] = useState<boolean>(false);
  const [applicant_signature, setapplicant_signature] = useState<string>();


  const [updateFailed, setupdateFailed] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [updateOk, setupdateOK] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);

  const [openTermsModal, setopenTermsModal] = useState<string | undefined>();
  const [termsOk, setTermsOK] = useState<boolean>(false);

  const [OpenAdminModal, setOpenAdminModal] = useState<string | undefined>();
  const [Approved, setApproved] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [Err_BidDate, setErr_BidDate] = useState<string>("");
  const [Err_BidTime, setErr_BidTime] = useState<string>("");
  const [project_status, setProject_Status] = useState<string>("");
  const [bid_status, setbid_status] = useState<string>("");
  const [BidTimeIsPm, setBidTimeIsPm] = useState<boolean>(true);
  const [GcNames, setGcNames] = useState<string[]>([]);
  const [GcEmployerName, setGcEmployerName] = useState<string>("");
  const [gcMatches, setGcMatches] = useState<string[]>([]);
  const [projectHeader, setProjectHeader] = useState<string>("");
  const [failProject, setFailProject] = useState<boolean>(false);
  const [application_created_date, setapplication_created_date] = useState<Date>(new Date());

  
  const [statusDisplay, setStatusDisplay] = useState<string>("");

  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  



  //const [toastMessage, setToastMessage] = useState<boolean>(false);
  let navigate = useNavigate();
  const { bidId } = useParams()


  useEffect(() => {


    document.title = window.location.hostname.endsWith(".ieorgtrust.com") ? "IEOT Portal" : window.location.hostname.endsWith("nworgtrust.com") ? "NWOT Portal" : "WEQ" + window.location.hostname

    let avc = new apiServices();

    setIsAdmin(avc.hasHole("Admin"));


    let svc = new bidServices();

    svc.getGCs().then((gcNames) => {
      setGcNames(gcNames);
    });



    if (bidId)
    {
      setloading(true);

    svc.getBids(parseInt(bidId)).then((BidList) => {
      setloading(false);  

      var b = BidList[0];
      setBid_Date(b.bid_Date);

      let headerStatus = b.project_status;

      if (b.bid_status.toLowerCase() == "lost") {
        // if we're not the admin, and we lost, show lost
        setProject_Status("Lost");
        headerStatus = isAdmin ? "Project Status - " + b.project_status + " Bid Status - Lost" : "Lost";
      }
      else {
        setProject_Status(b.project_status);
      }


      setProjectHeader(b.employer_Name + " Ref#" + b.project_Reference_Number + " " + headerStatus);
      setBid_Time(b.bid_Time);
      setCompetition(b.competition);
      setCompetition_Reason(b.competition_Reason);
      setContact(b.contact);
      setcontact_email(b.contact_email);
      setEngineers(b.engineers);
      setEquipment_Suppliers(b.equipment_Suppliers);
      setFab_Contractor(b.fab_Contractor);
      setField_Hours(b.field_Hours);
      setProject_Address(b.project_Address);
      setBidTimeIsPm(b.bidTimePm);

      setProject_City(b.project_City);
      setProject_County(b.project_County);
      setProject_Name(b.project_Name);
      setProject_Type(b.project_Type.trim());
      setShop_Hours(b.shop_Hours);

      setapplicant_signature(b.applicant_signature);
      setcontact_email(b.contact_email);
      setcontact_phone(b.contact_phone);
      setless_than_10k_ft2(b.less_than_10k_ft2);
      setprevailing_wage(b.prevailing_wage);
      setField_Hours(b.field_Hours);

      setHours_Requested(b.shop_Hours + b.field_Hours);
      setDecline_Reason(b.decline_Reason);
      setHours_Granted(b.hours_Granted);
      setHours_Rate(b.hours_Rate);
      setApproved(b.amount_Granted > 0);
      setHours_Rate(b.hours_Rate);
      setHours_Granted(b.hours_Granted);
      setbid_status(b.bid_status);
      setGcEmployerName(b.gcEmployerName)
      setapplication_created_date(convertUTCDateToLocalDate(b.application_created_date));
      

      if (b.bid_status == "Requested - Pending Approval") {
        b.hours_Granted = b.hours_Requested;
      }

    }
    ).catch((e) => {
      console.log(e);
      setloading(false);
      //  if(e.type=="RefreshTokenException" || e.responseStatus.errorCode == "403" || e.responseStatus.errorCode == "401")
      {
        navigate("/login");

      }
    });
  }
  }, [isAdmin]);

  function convertUTCDateToLocalDate(date:any) {
    console.log(date);

    var dateLocal = new Date(date);
    var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset()*60*1000);
    return newDate;
  }


  const selectGC = (tgt: string) => {
    setGcEmployerName(tgt);
    setGcMatches([]);

  }

  const updateGCName = (tgt: string) => {
    setGcEmployerName(tgt);
    if (tgt) {
      setGcMatches(GcNames.filter(function (str: string) { return str.toUpperCase().includes(tgt.toUpperCase()) }));
    }
    else {
      setGcMatches([]);
    }
  }


  const agreeToTerms = () => {

    setopenTermsModal(undefined);
    setTermsOK(true);
  }



  const updateBidTime =
    (dt: string) => {


      var reGoodDate = /^[0-1][0-9]:[0-5][0-9]$/

      setBid_Time(dt);


      if (reGoodDate.test(dt)) {
        var hr = parseInt(dt.substring(0, 2));

        if (hr > 12) {
          hr -= 12;
          var hrStr = hr >= 10 ? hr.toString() : "0" + hr.toString();
          var minStr = dt.substring(2);
          setBid_Time(hrStr + minStr);

        }

        if (hr >= 6 && hr < 12) {
          setBidTimeIsPm(false);
        }
        else {
          setBidTimeIsPm(true);
        }


        setErr_BidTime("");

      }
      else {
        setErr_BidTime("Please enter a time in the form of hh:mm");

      }


    }

  const updateBidDate =
    (dt: string) => {


      var reGoodDate = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;

      setBid_Date(dt);

      if (reGoodDate.test(dt)) {
        setErr_BidDate("");

      }
      else {
        setErr_BidDate("Please enter a date in the form of mm/dd/yyyy or mm/dd/yy ");

      }


    }
  const updateShopHrs = (value: string) => {

    let intval = Number.parseInt(value);
    setShop_Hours(intval);

    setHours_Requested(intval + Field_Hours);

  }

  function isNumber(str: string): boolean {
    if (typeof str !== 'string') {
      return false;
    }

    if (str.trim() === '') {
      return false;
    }

    return !Number.isNaN(Number(str));
  }

  const okToSubmit = () => {

    let errMsg = "";

    return (!bidId && !isNumber(bidId)) 
    && Project_Name
    && Project_Address
    && Project_City
    && Project_County
    && Competition
    && Bid_Date
    && Bid_Time
    && Field_Hours+Shop_Hours>0 
    && contact_email
    && contact_phone
    && Contact
    && applicant_signature 
    && applicant_signature.length>=2 &&  termsOk && !errMsg;

  }


  const updateFieldHours = (value: string) => {

    let intval = Number.parseInt(value);
    setField_Hours(intval);

    setHours_Requested(Shop_Hours + intval);

  }







  const updateApproval = () => {
    setloading(true);
    let svc = new bidServices();


    svc.updateApproval(
      failProject, parseInt(bidId), Decline_Reason, Hours_Granted, Hours_Rate
    ).then(() => {
      setOpenAdminModal(undefined)

      setloading(false);
      setupdateFailed(false);
      setupdateOK(true);
      navigate("/");

    }).catch((e: any) => {
      console.log(e);
      setloading(false);
      var message = e.responseStatus.message ? e.responseStatus.message : JSON.stringify(e);

      setErrorMessage("Error updating status - " + message);

      scrollToBottom();

      setupdateFailed(true);

    });


  }

  const toggleApproved = () => {

    if (!Approved) // going to approved
    {
      setDecline_Reason(null);
      setFailProject(false);
    }
    else {
      setHours_Granted(null);
      setHours_Rate(null);
    }

    setApproved(!Approved)
  }

  const handleUpdate = () => {


    setloading(true);

    setupdateFailed(false);
    setupdateOK(true);
    setErrorMessage("");


    let svc = new bidServices();

    let bidTimeAmPm = Bid_Time + (BidTimeIsPm ? " pm" : " am");

    svc.submitBid(bidId,
      applicant_signature, Bid_Date, Contact, contact_email,
      contact_phone, Field_Hours, less_than_10k_ft2, prevailing_wage,
      Shop_Hours, Project_Address, Project_City, Project_County,
      Project_Name, Project_Type, Project_Name, Field_Hours, Fab_Contractor, Engineers, Equipment_Suppliers, Competition_Reason, Competition, bidTimeAmPm, GcEmployerName

    ).then(() => {

      navigate("/");
      setloading(false);
      setupdateFailed(false);
      setupdateOK(true);

    }).catch((e: any) => {
      console.log(e);
      setloading(false);
      var message = e.responseStatus.message ? e.responseStatus.message : JSON.stringify(e);

      setErrorMessage("Error submitting bid - " + message);

      scrollToBottom();

      setupdateFailed(true);
    });



  }



  const handleNewSubmissions = () => {


    setloading(true);
    setloading(false);
    setupdateFailed(false);
    setupdateOK(true);
    setErrorMessage("");


    localStorage.setItem("FormAContact", Contact);
    localStorage.setItem("FormAcontact_email", contact_email);
    localStorage.setItem("FormAcontact_phone", contact_phone);


    let bidTimeAmPm = Bid_Time + (BidTimeIsPm ? " pm" : " am");

    let svc = new bidServices();
    svc.submitBid("0",
      applicant_signature, Bid_Date, Contact, contact_email,
      contact_phone, Field_Hours, less_than_10k_ft2, prevailing_wage,
      Shop_Hours, Project_Address, Project_City, Project_County,
      Project_Name, Project_Type, Project_Name, Field_Hours, Fab_Contractor, Engineers, Equipment_Suppliers, Competition_Reason, Competition, bidTimeAmPm,
      GcEmployerName

    ).then(() => {

      navigate("/");
      setloading(false);
      setupdateFailed(false);
      setupdateOK(true);

    }).catch((e: any) => {
      console.log(e);
      setloading(false);
      var message = e.responseStatus.message ? e.responseStatus.message : JSON.stringify(e);

      setErrorMessage("Error submitting bid - " + message);

      scrollToBottom();

      setupdateFailed(true);
    });



  }





  return (
    <div className="mx-auto flex max-w-6xl flex-col gap-8 dark:text-white">

      <Modal show={OpenAdminModal === 'default'} onClose={() => setOpenAdminModal(undefined)}>
        <Modal.Header>
          Approve Funds or Deny - Hours Requested = {Hours_Requested}
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">

              <ToggleSwitch
                checked={Approved}
                label="Approved"
                onChange={() => toggleApproved()}
              />




              {!Approved &&
                <label htmlFor="decline_reason" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Decline Reason</label>}


              {!Approved &&

                <Select

                  required={true}
                  value={Decline_Reason}
                  onChange={e => setDecline_Reason(e.target.value)}
                >
                  <option value="">
                    Choose a Reason
                  </option>
                  <option value="NO-INSUFFICIENT FUNDS">
                    Insufficient Funds
                  </option>
                  <option value="NO-OUT OF AREA">
                    Out of Area
                  </option>

                  <option value="NO-UNABLE TO VERIFY JOB">
                    Unable to verify
                  </option>
                  <option value="NO-UNTIMELY REQUEST">
                    Untimely Request
                  </option>
                  <option value="NO-UNION ONLY">
                    Union Only
                  </option>
                  <option value="NO-PREVAILING WAGE">
                    Prevailing Wage
                  </option>
                  <option value="NO-DAVIS-BACON NOT ALLOWED">
                    Davis-Bacon Not Allowed
                  </option>

                  <option value="NO-DELINQUENT CONTRACTOR">
                    Delinquent Contractor
                  </option>

                  <option value="NO-DUPLICATE">
                    Duplicate Submission
                  </option>


                  <option value="NO-NO SUBSIDY">
                    This project is not being subsidized by the Trust
                  </option>



                </Select>


              }
<hr/>
              {Decline_Reason && <ToggleSwitch
                checked={failProject}
                disabled={false}
                label={failProject ? "Fail project" : "Don't fail project - awaiting other bids"}
                onChange={() => setFailProject(!failProject)}
              />}

              {Approved &&
                <label htmlFor="Hours_Granted" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Assistance Hours</label>
              }

              {Approved &&
                <input id="Hours_Granted" type="number" value={Hours_Granted} onChange={e => setHours_Granted(parseInt(e.target.value))} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
              }
              {Approved &&
                <label htmlFor="amount_granted" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Per Hour Rate $</label>
              }
              {Approved &&
                <input id="hours_rate_granted" type="number" value={Hours_Rate} onChange={e => setHours_Rate(parseFloat(e.target.value))} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
              }


            </p>
          </div>
        </Modal.Body>
        {loading && <Modal.Footer>Please Wait...</Modal.Footer>}
        {!loading && <Modal.Footer>

          {((Approved && Hours_Granted > 0 && Hours_Rate > 0) ||
            (!Approved && Decline_Reason)) &&

            <Button onClick={() => updateApproval()}>
              {Approved ? "Approve Assistance" : "Decline Request " + (failProject ? " And fail Project " : "")}
            </Button>
          }
        </Modal.Footer>}
      </Modal>
      {!bidId && <span className="text-2xl font-bold">Submit a New Request For Assistance ("Form A")</span>}
      {bidId &&
        <div>

          <span className="text-2xl font-bold">Request for Assistance {projectHeader}</span>
        </div>

      }


      <Badge color={project_status.toLowerCase() == "successful" ||
        (bid_status.toLowerCase() != "declined" && bid_status.toLowerCase() != "lost" && project_status.toLowerCase() == "pending") ||
        project_status.toLowerCase() == "completed"
        ? "success" : "failed"}> Status: {bidId ? project_status : "New Request"} --  Submitted By:  <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href={"mailto:" + contact_email}>{contact_email} </a> @ {application_created_date.toLocaleString()} </Badge>
      <form>
        <div className="grid gap-6 mb-6 md:grid-cols-8">
          <div className="col-span-2">
            <label htmlFor="Project_Name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Project Name*</label>
            <input id="Project_Name" value={Project_Name} onChange={e => setProject_Name(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />

          </div>
          <div className="col-span-2">
            <label htmlFor="Project_Address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Project Address*</label>
            <input id="Project_Address" value={Project_Address} onChange={e => setProject_Address(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />

          </div>
          <div className="col-span-1">
            <label htmlFor="Project_City" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Project City*</label>
            <input id="Project_City" value={Project_City} onChange={e => setProject_City(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />

          </div>
          <div className="col-span-1">
            <label htmlFor="Project_County" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Project County*</label>
            <input id="Project_County" value={Project_County} onChange={e => setProject_County(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />

          </div>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-8">
          <div className="col-span-2">
            <label htmlFor="General_Contractor" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              General Contractor</label>

            <input id="General_Contractor" value={GcEmployerName} onChange={e => updateGCName(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
            {gcMatches.length > 0 &&
              <ul>
                {gcMatches.map((item) => (
                  <li onClick={() => selectGC(item)} key={item}>{item}</li>
                ))}

              </ul>
            }
          </div>
          <div className="col-span-1">
            <label htmlFor="Bid_Date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Bid Date*</label>

            <input disabled={bidId && !isAdmin} id="Bid_Date" placeholder="mm/dd/yyyy" value={Bid_Date} onChange={e => updateBidDate(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            {Err_BidDate && <Badge color="failure">{Err_BidDate}</Badge>}
          </div>
          <div className="col-span-1">
            <label htmlFor="Bid_Time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Time*</label>
            <input  disabled={bidId && !isAdmin}  id="Bid_Time" value={Bid_Time} placeholder="e.g. 01:00" onChange={e => updateBidTime(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            {Err_BidTime && <Badge color="failure">{Err_BidTime}</Badge>}
            <ToggleSwitch
              checked={BidTimeIsPm}
              disabled={bidId && !isAdmin}
              label={BidTimeIsPm ? "pm" : "am"}
              onChange={() => setBidTimeIsPm(!BidTimeIsPm)}
            />
          </div>
          <div className="col-span-1">
            <label htmlFor="prevailing_wage" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Prevailing Wage</label>
            <Checkbox id="prevailing_wage" checked={prevailing_wage}
              onChange={() => setprevailing_wage(!prevailing_wage)}></Checkbox>

          </div>
          <div className="col-span-1">
            <label htmlFor="less_than_10k_ft2" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              under 10K sqft</label>
            <Checkbox id="less_than_10k_ft2"  checked={less_than_10k_ft2}
              onChange={() => setless_than_10k_ft2(!less_than_10k_ft2)}></Checkbox>

          </div>

        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-8">
          <div className="col-span-2">
            <label htmlFor="Engineers" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Engineers</label>

            <input id="Engineers" placeholder="" value={Engineers} onChange={e => setEngineers(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />

          </div>
          <div className="col-span-2">
            <label htmlFor="Equipment_Suppliers" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Equip. Suppliers</label>
            <input id="Equipment_Suppliers" value={Equipment_Suppliers} placeholder="" onChange={e => setEquipment_Suppliers(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />

          </div>
          <div className="col-span-2">
            <label htmlFor="Fab_Contractor" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Fab Contractor</label>
            <input id="Fab_Contractor" value={Fab_Contractor} placeholder="" onChange={e => setFab_Contractor(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />

          </div>
        </div>
        <div className="grid gap-6 mb-6 md:grid-cols-8">

          <div className="col-span-2">
            <label htmlFor="Competition" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Competition*</label>
            <input id="Competition" value={Competition} placeholder="Competing Firms" onChange={e => setCompetition(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />

          </div>
          <div className="col-span-6">
            <label htmlFor="Competition_Reason" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Competition Notes</label>

            <input id="Competition_Reason" value={Competition_Reason} placeholder="What leads you to believe the other contractors are bidding on this contract" onChange={e => setCompetition_Reason(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />

          </div>

        </div>
        <div className="grid gap-6 mb-6 md:grid-cols-8">
          <div className="col-span-2">
            <label htmlFor="Project_Type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Project Type*</label>
            <Select

              required={true}
              value={Project_Type}
              onChange={e => setProject_Type(e.target.value)}
            >
              <option value="Architectural">
                Architectural
              </option>

              <option value="HVAC (B/T Rate)">
                HVAC (B/T Rate)
              </option>
              <option value="HVAC (L/C Rate)">
                HVAC (L/C Rate)
              </option>
              <option value="Industrial">
                Industrial
              </option>
              <option value="TAB">
                TAB
              </option>

              <option value="Kitchen">
                Kitchen
              </option>
              <option value="Residential">
                Residential
              </option>
              <option value="Service">
                Service
              </option>
            </Select>


          </div>
          <div className="col-span-1">
            <label htmlFor="Shop_Hours" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Shop Hours*</label>
            <input disabled={!isAdmin && bidId && parseInt(bidId) > 0} id="Shop_Hours" value={Shop_Hours} onChange={e => updateShopHrs(e.target.value)} type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />

          </div>
          <div className="col-span-1">
            <label htmlFor="Field_Hours" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Field Hours*</label>
            <input disabled={!isAdmin && bidId && parseInt(bidId) > 0} id="Field_Hours" value={Field_Hours} onChange={e => updateFieldHours(e.target.value)} type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />

          </div>

          <div className="col-span-1">
            <label htmlFor="ttlHours" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Total Hours</label>
            <input disabled id="ttlHours" value={Hours_Requested} type="number" className="font-bold block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />

          </div>


        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-8">
          <div className="col-span-2">
            <label htmlFor="Contact" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Contact Name*</label>
            <input id="Contact" value={Contact} onChange={e => setContact(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />

          </div>
          <div className="col-span-2">
            <label htmlFor="contact_email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Email*</label>
            <input id="contact_email" placeholder="user@domain.com" value={contact_email} onChange={e => setcontact_email(e.target.value)} type="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />


          </div>
          <div className="col-span-1">
            <label htmlFor="contact_phone" placeholder="206-867-5309" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Contact Phone*</label>
            <input id="contact_phone" value={contact_phone} onChange={e => setcontact_phone(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />

          </div>
          <div className="col-span-2">
            <label htmlFor="applicant_signature" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Your Signature*</label>
            <input disabled={bidId && parseInt(bidId) > 0} id="applicant_signature" placeholder="Type your full name" value={applicant_signature} onChange={e => setapplicant_signature(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />

          </div>
        </div>


        {isAdmin && bid_status.toLowerCase() != "declined" && project_status.toLowerCase() == "pending" && <Button onClick={() => setOpenAdminModal('default')}>Approve/Deny</Button>}
        {loading && <div>Please wait...</div>}

        {!loading && okToSubmit() && <Button onClick={handleNewSubmissions} >Send New Request</Button>}
        
        {!loading && project_status.toLowerCase() == "pending" &&
          bidId && parseInt(bidId) > 0 && <Button onClick={handleUpdate}>Update Bid & Project</Button>}
        {!loading && !okToSubmit() && <div>Please fill required fields and agree to terms...</div>}
        


        {updateFailed && <Alert color="failure" icon={HiInformationCircle}>

          {errorMessage}
        </Alert>}
        <div ref={messagesEndRef} />

        {loading && <Spinner
          color="success"

        />}
        {updateOk &&
          <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
              <HiCheck className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm font-normal">
              Your Request has been received
            </div>
            <Toast.Toggle />
          </Toast>}


        {!isAdmin && !bidId && !termsOk && <Button onClick={() => setopenTermsModal('default')}>Terms</Button>}
        <Modal show={openTermsModal === 'default'} onClose={() => {
          setopenTermsModal(undefined);
          scrollToBottom();
        }
        }>
          <Modal.Header>
            Terms of Service
          </Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">

                By signing above, I certify the above information to be true and accurate. I also certify that our company has complied with all the requirements according to the Northwest Sheet Metal Organizational Trust Policies and Guidelines, including the following:
              </p>       <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">


                All subsidy payments previously awarded to any employer which have not yet been billed to or paid by the Trust, shall be suspended upon the Trust receiving notification of repudiation or attempted repudiation of the Collective Bargaining Agreement, or of the filing of any decertification petition or the pendency of any decertification election, until such decertification or repudiation is resolved.  In the event a decertification election is successful or the repudiation is successful, all suspended payments shall be deemed forfeited and be made available to the Trust.  In the event the decertification petition is terminated or the decertification election unsuccessful or the employer returns to full compliance with the Collective Bargaining Agreement, all suspended payments shall be made to the employer upon compliance with other Trust policies.
                I understand that participation may be suspended for non-compliance.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={agreeToTerms}>
              I accept
            </Button>

          </Modal.Footer>
        </Modal>


      </form>



    </div>



  );

};

export default SubmitBidFormAPage;
