import apiServices from './apiServices';
import { GetTrustFund }  from './dtos';



class adminServices  {
    

     baseSvc = new apiServices();
     client = this.baseSvc.client;

   
    constructor() {
     
      }


      getTrustFund()
      {
        const req = new GetTrustFund();
        return this.client.get(req);
        
      }
    

}


export default adminServices;
