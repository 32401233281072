import {ReactNode} from "react";
import './title.css';
import { TrustFund } from "../../../services/dtos";

interface Props {
    trust?:TrustFund,
    children?: ReactNode
    // any props that come into the component
}

const FormBTitle  = ({ children, ...props }: Props) => {

    return(
        <div id="title-wrapper">
            <div id="title">
                <h1>{props.trust.trust_name}</h1>
            </div>
            <div id="subtitle">
                <div>{props.trust.address} {props.trust.city} {props.trust.state} {props.trust.postal_code} </div>

            </div>
            <div id="form-name">
                <h1>AWARDED (FORM B)</h1>
            </div>
        </div>
        
    )
}

export default FormBTitle;