import { JsonServiceClient } from '@servicestack/client';
import { Authenticate, UserRegister }  from './dtos';


class apiServices  {
    
   


    public client : JsonServiceClient;

   
    constructor() {

        
        let url = "";
     //   console.log(process.env.NODE_ENV);

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') 
        {
           
            url = "https://localhost:5001";
            
        }
        this.client =  new JsonServiceClient(url);
  

        this.client.bearerToken= localStorage.getItem("JsonServiceClient_bearerToken");
        this.client.refreshToken = localStorage.getItem("JsonServiceClient_refreshToken");
       
      }


      public hasHole = (roleName:string) :boolean =>
      {

        if(!this.client.bearerToken)
        {
            return false;
        }

        var token = this.parseJwt(this.client.bearerToken);
        if(token!=null)
        {
            var roles = this.parseJwt(this.client.bearerToken).roles;
            if(roles && roles.indexOf(roleName) > -1)
            {
                return true;
            }
        }

        return false;


        

      }

      public isloggedin = () => {
       
        if(
            this?.client!=null 
           && this.client.bearerToken!=null 
           && this.client.bearerToken!=""
           &&  this.client.bearerToken!="null")
           {
               return true;
           }
           else
           {
               return false;
           }
    }


    logout()
    {
      
        localStorage.removeItem("JsonServiceClient_bearerToken");
        localStorage.removeItem("JsonServiceClient_refreshToken");
        
        const req = new Authenticate();
        req.provider = "logout";

        this.client.post(req);

        delete this.client.bearerToken;
        delete this.client.refreshToken;

    }


    register(email:string,password:string,invite_code:string,  captcha_value:string)
    {
        this.logout();
        

        const request = new UserRegister();
        request.email = email;
        request.password = password;
        request.employer_invite_code = invite_code;
        request.captcha_value = captcha_value;

        return this.client.post(request).then(() => {
           
    
        });


    }
    login(email:string,password:string) {
    
    const request = new Authenticate();
    request.provider = "credentials";
    request.userName = email;
    request.password = password;
    request.rememberMe = true;
    this.client.refreshToken=null;
    this.client.bearerToken=null;

    return this.client.post(request).then((r) => {
        this.client.refreshToken = r.refreshToken;
        this.client.bearerToken = r.bearerToken;
        
        localStorage.setItem("JsonServiceClient_bearerToken", r.bearerToken);
        localStorage.setItem("JsonServiceClient_refreshToken", r.refreshToken);


    }
    );
    
    

    }
    parseJwt (token:string) {
        if(!token || token.split(".").length<2)
        {
            return null;
        }
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }
    





}




export default apiServices;
