/* Options:
Date: 2023-08-06 08:45:35
Version: 6.50
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:5001

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export class BidDisplay
{
    public project_Name: string;
    public bidTimePm: boolean;
    public hours_Granted: number;
    public project_Address: string;
    public project_City: string;
    public project_County: string;
    public other_contractor_1: string;
    public other_contractor_1_bid: number;
    public application_created_date: string;
    public action_required: string;
    public approved_won_formc_date?: string;
    public other_contractor_2: string;
    public other_contractor_2_bid: number;
    public other_contractor_3: string;
    public other_contractor_3_bid: number;
    public total_Bid: number;
    public project_Type: string;
    public decline_Reason: string;
    public shop_Hours: number;
    public field_Hours: number;
    public fab_Contractor: string;
    public engineers: string;
    public equipment_Suppliers: string;
    public competition_Reason: string;
    public competition: string;
    public employer_ID: number;
    public hours_Requested: number;
    public bid_Date: string;
    public bid_Time: string;
    public contact: string;
    public contact_email: string;
    public contact_phone: string;
    public prevailing_wage?: boolean;
    public less_than_10k_ft2?: boolean;
    public est_start_date?: string;
    public est_end_date?: string;
    public approved_date: string;
    public applicant_signature?: string;
    public bid_Id: number;
    public total_Hours: number;
    public bid_status: string;
    public gcEmployerName: string;
    public employer_Name: string;
    public project_status: string;
    public project_Reference_Number: string;
    public amount_Granted: number;
    public hours_Rate: number;
    public project_Id: number;

    public constructor(init?: Partial<BidDisplay>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class TrustFund
{
    public local_name: string;
    public trust_name: string;
    public domain_name: string;
    public address: string;
    public city: string;
    public state: string;
    public postal_code: string;
    public email_address: string;
    public contact_phone: string;

    public constructor(init?: Partial<TrustFund>) { (Object as any).assign(this, init); }
}

export class apiResponse
{
    public message: string;

    public constructor(init?: Partial<apiResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegisterResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public referrerUrl: string;

    // @DataMember(Order=5)
    public bearerToken: string;

    // @DataMember(Order=6)
    public refreshToken: string;

    // @DataMember(Order=7)
    public roles: string[];

    // @DataMember(Order=8)
    public permissions: string[];

    // @DataMember(Order=9)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=10)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegisterResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @Route("/trustfund", "GET")
export class GetTrustFund implements IReturn<TrustFund>
{

    public constructor(init?: Partial<GetTrustFund>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTrustFund'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrustFund(); }
}

// @Route("/gc", "GET")
export class getGCsReq implements IReturn<string[]>
{

    public constructor(init?: Partial<getGCsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getGCsReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/gc", "POST")
export class AddGCReq implements IReturn<number>
{
    public employer_Name: string;

    public constructor(init?: Partial<AddGCReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddGCReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return 0; }
}

// @Route("/bid/admin", "PUT")
export class SubsidyApproveOrDeny implements IReturn<apiResponse>
{
    public bid_Id: number;
    public project_Area: string;
    public decline_Reason: string;
    public hours_Granted: number;
    public hours_rate: number;
    public fail_project: boolean;

    public constructor(init?: Partial<SubsidyApproveOrDeny>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubsidyApproveOrDeny'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new apiResponse(); }
}

// @Route("/formcbidupdate", "PUT")
export class FormCBidUpdate implements IReturn<apiResponse>
{
    public bid_Id: number;
    public approved_by_admin: boolean;
    public fail_project: boolean;
    public my_bid_amt: number;
    public my_bid_status: string;
    public other_contractor_1: string;
    public other_contractor_1_bid: number;
    public other_contractor_2: string;
    public other_contractor_2_bid: number;
    public other_contractor_3: string;
    public other_contractor_3_bid: number;

    public constructor(init?: Partial<FormCBidUpdate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormCBidUpdate'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new apiResponse(); }
}

// @Route("/mergebid", "PUT")
export class mergeBidsRequests implements IReturnVoid
{
    public bid_Ids: number[];
    public project_Id: number;

    public constructor(init?: Partial<mergeBidsRequests>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'mergeBidsRequests'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/bid", "GET")
export class GetBidsReq implements IReturn<BidDisplay[]>
{
    public bidId?: number;

    public constructor(init?: Partial<GetBidsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBidsReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<BidDisplay>(); }
}

// @Route("/bid", "POST,PUT")
export class BidSubmission
{
    public bid_id: number;
    public project_Name: string;
    public project_Address: string;
    public project_City: string;
    public project_County: string;
    public gcEmployerName: string;
    public project_Type: string;
    public shop_Hours: number;
    public field_Hours: number;
    public fab_Contractor: string;
    public engineers: string;
    public equipment_Suppliers: string;
    public competition_Reason: string;
    public competition: string;
    public employer_ID: number;
    public hours_Requested: number;
    public bid_Date: string;
    public bid_Time: string;
    public contact: string;
    public contact_email: string;
    public contact_phone: string;
    public prevailing_wage?: boolean;
    public less_than_10k_ft2?: boolean;
    public est_start_date?: string;
    public est_end_date?: string;
    public applicant_signature?: string;

    public constructor(init?: Partial<BidSubmission>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BidSubmission'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

export class notifyFormCRequired implements IReturnVoid
{

    public constructor(init?: Partial<notifyFormCRequired>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'notifyFormCRequired'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/admin/smsusage2", "GET")
export class getusageReq implements IReturnVoid
{
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<getusageReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getusageReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class SendSms implements IReturnVoid
{
    public to: string;
    public message: string;
    public message_type: string;

    public constructor(init?: Partial<SendSms>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSms'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SendEmail implements IReturnVoid
{
    public to: string;
    public subject: string;
    public body: string;
    public message_type: string;

    public constructor(init?: Partial<SendEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/registeruser", "POST")
export class UserRegister implements IReturn<RegisterResponse>
{
    public email: string;
    public firstName: string;
    public lastName: string;
    public password: string;
    public employer_invite_code: string;
    public captcha_value: string;

    public constructor(init?: Partial<UserRegister>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UserRegister'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegisterResponse(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

