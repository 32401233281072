import type { FC } from 'react';
import { useEffect } from 'react';

import { Spinner, Table, Button, Badge, Alert } from '../../lib';
import { HiInformationCircle } from 'react-icons/hi';
import bidServices from '../../services/bidServices';
import apiServices from '../../services/apiServices';

import { BidDisplay } from '../../services/dtos';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { timeStamp } from 'console';
import { action } from '@storybook/addon-actions';


type Props = {
  isLoggedIn: boolean;
  setLoggedIn: (val: boolean) => void;

};

const BidHistoryPage: FC<Props> = () => {



  const [Emp_Search, setEmp_Search] = useState<string>("");


  const [Status_Search, setStatus_Search] = useState<string>("");

  const [ProjectName_Search, setProjectName_Search] = useState<string>("");
  const [bidDateSearch, setbidDateSearch] = useState<string>("");


  const [merge, setMergeArray] = useState<any>({});
  const [mergeWinnerProjectId, setmergeWinnerProjectId] = useState<number>(0);

  const [jobHist, setJobHist] = useState<Array<BidDisplay>>(null);
  const [loading, setLoading] = useState(false);
  const [merging, setMerging] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  let navigate = useNavigate();

  let svc = new apiServices();

  let isAdmin = svc.hasHole("Admin");

  const isPrimaryMergeTo = function (project_id: number) {
    return mergeWinnerProjectId == project_id;
  }

  const setWinner = (bid_Id: number, project_Id: number) => {
    setmergeWinnerProjectId(project_Id);
    setMerge(bid_Id, project_Id, null, true);

  }

  const doMerge = () => {

    let svc = new bidServices();

    var bidsIdsToMerge = new Array<number>();

    for (const k in merge) {
      if (merge[k]) {
        bidsIdsToMerge.push(parseInt(k));
      }
    }

    svc.mergeBidProjects(bidsIdsToMerge, mergeWinnerProjectId).then((data) => {
      setMerging(false);
      setmergeWinnerProjectId(0);
      setMergeArray({});
      updateBids();
    }
    );

  }

  const setMerge = (bid_Id: number, project_id: number, tgt: any, forcePicked: boolean) => {

    const newState = Object.assign({}, merge);

    if (forcePicked || !newState.hasOwnProperty(bid_Id)) {
      newState[bid_Id] = true;
    }
    else
      newState[bid_Id] = tgt.checked;

    if (!forcePicked && !tgt.checked && mergeWinnerProjectId == project_id) {
      setmergeWinnerProjectId(0);
    }
    else {
      if (mergeWinnerProjectId == 0 && !forcePicked) {
        setmergeWinnerProjectId(project_id);
      }
    }

    setMergeArray(newState);


  }

  const getBidActionUrl = (action_required: string, bid_Id: number): string => {

    if (!action_required)
      return "";

    if (action_required.indexOf("Form A") > 0 || action_required == "Admin - Approve/Deny Subsidy") {
      return "/FormA/" + bid_Id;
    }

    if (action_required.indexOf("Form C") > 0) {
      return "/submitFormC/" + bid_Id;
    }



    return "";
  }

  const updateBids = () => {
    let apisvc = new apiServices();
    if(!apisvc.isloggedin)
    {
      navigate("/login");
    }
    setLoading(true);

    let svc = new bidServices();



    svc.getBids(null).then((res) => {
      setJobHist(res);

      setLoading(false);
    }
    ).

      catch((e) => {
        let apisvc = new apiServices();
   

        console.log(e);
        setLoading(false);
        if (e.responseStatus && (e.responseStatus.errorCode == "403" || e.responseStatus.errorCode == "401")) {
          apisvc.logout();
          navigate("/login");

        }

        // check crudely for token or other errors
        let msg = JSON.stringify(e).toLocaleLowerCase();

        if(msg.indexOf("token") >=0 || msg.indexOf("expired")>0)
        {
          apisvc.logout();
          navigate("/login");

        }
        else
        {
          setErrorMessage(e.message + ":" + e.stack + " " + JSON.stringify(e));
        }
        
      });


  };



  useEffect(() => {
    updateBids();

    document.title = window.location.hostname.endsWith(".ieorgtrust.com") ? "IEOT Portal" : window.location.hostname.endsWith("nworgtrust.com") ? "NWOT Portal" : "WEQ" + window.location.hostname

  }, []);

  // <div className="mx-auto flex w-full flex-col gap-4  dark:text-gray-400">

  return (



    <div className="overflow-x-auto relative">

      {errorMessage && <Alert color="failure" icon={HiInformationCircle}>
        {errorMessage}

      </Alert>}

      <span className="text-2xl font-bold dark:text-white">Subsidy History</span>
      {!isAdmin && <Button onClick={() => navigate("/FormA")}>Create new Subsidy Request</Button>}

      {loading && <Spinner
        color="success"

      />}

      {isAdmin && !merging && <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white"><a href="#" onClick={() => setMerging(true)}>Merge Projects</a></p>}
      {isAdmin && merging && mergeWinnerProjectId > 0 && <p><Button onClick={() => doMerge()}>Perform Merge (keep primary's data)</Button></p>}

      <Table className="w-full text-xs text-left text-gray-500 dark:text-gray-400">
        <Table.Head>
          {isAdmin && merging && <Table.HeadCell>
            Choose Projects to merge and a primary

          </Table.HeadCell>}

          {isAdmin && <Table.HeadCell>
            Employer

            <input id="empSearch" value={Emp_Search} onChange={e => setEmp_Search(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />


          </Table.HeadCell>}

          <Table.HeadCell>
            Project Name / City / Type
            <input id="NameSearch" value={ProjectName_Search} onChange={e => setProjectName_Search(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
          </Table.HeadCell>
          <Table.HeadCell>
            Bid Date

            <input id="bidDateSearch" value={bidDateSearch} onChange={e => setbidDateSearch(e.target.value)} type="text"  placeholder=""  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />


          </Table.HeadCell>

          <Table.HeadCell>
            Trust Project Id #
          </Table.HeadCell>
          <Table.HeadCell>
            Status

            <input id="Status_Search" value={Status_Search} onChange={e => setStatus_Search(e.target.value)} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />


          </Table.HeadCell>
          <Table.HeadCell>
            Assistance Amount / Decline Reason
          </Table.HeadCell>

        </Table.Head>
        <Table.Body className="divide-y">

          {jobHist && jobHist.length > 0 && jobHist.filter(j => !bidDateSearch || (j.bid_Date && j.bid_Date.toLowerCase().includes(bidDateSearch.toLowerCase()))).filter(j => !ProjectName_Search || (j.project_Name && j.project_Name.toLowerCase().includes(ProjectName_Search.toLowerCase()))).filter(j => !Status_Search || (j.bid_status && j.bid_status.toLowerCase().includes(Status_Search.toLowerCase()))).filter(j => !Emp_Search || j.employer_Name.toLowerCase().includes(Emp_Search.toLowerCase())).map(({ project_Id, bid_Id,   employer_Name, total_Bid, hours_Granted, decline_Reason, project_Name, hours_Rate, amount_Granted, action_required, project_Reference_Number, project_Type, project_City, bid_status, project_status, bid_Date }, index) => (

            <Table.Row key={bid_Id} className="bg-white text-xs dark:border-gray-700 dark:bg-gray-800">

              {isAdmin && merging && <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">


                Include In Merge: <input checked={merge[bid_Id]} id={"merge" + bid_Id} type="checkbox" onChange={e => setMerge(bid_Id, project_Id, e.target, false)}></input>
                <p></p>
                <label htmlFor={"primary" + bid_Id}
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Primary (Keep Data)
                </label>

                <input checked={isPrimaryMergeTo(project_Id)} id={"primary" + bid_Id} type="checkbox" onChange={e => setWinner(bid_Id, project_Id)}></input>

              </Table.Cell>
              }
              {isAdmin && <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {employer_Name}
              </Table.Cell>
              }
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">

                <a href="" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => navigate("/FormA/" + bid_Id)} >{project_Name} - {project_City} - {project_Type} </a>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {bid_Date}
              </Table.Cell>

              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {project_Reference_Number}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {bid_status}
                
                {bid_status && bid_status != "Requested - Pending Approval" &&  bid_status.toLowerCase() != "declined" && bid_status.toLowerCase() != "lost" && total_Bid==0 && <a href={"/submitFormC/" + bid_Id}><Badge color="failure">Form C</Badge></a>} 
                {bid_status && bid_status.toLowerCase() != "awarded - accepted" && bid_status.toLowerCase() != "declined" && bid_status.toLowerCase() != "lost" && total_Bid>0 && <a href={"/submitFormC/" + bid_Id}><Badge  color="success">Form C</Badge></a>} 

                {action_required && bid_status && bid_status.toLowerCase() != "declined" && isAdmin && action_required.indexOf("Admin") >= 0 && <a href={getBidActionUrl(action_required, bid_Id)}><Badge onClick={() => navigate(getBidActionUrl(action_required, bid_Id))} color="warning">{action_required}</Badge></a>}
                {bid_status && bid_status.toLowerCase() != "lost" && project_status.toLowerCase() == "successful"  && <a href={"/formb/" + bid_Id}><Badge  color="info">Form B</Badge></a>} 



              </Table.Cell>
              {!decline_Reason && hours_Granted > 0 && <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">

                {hours_Granted}hrs @ ${hours_Rate}/hr {isAdmin && <div>Total:$ {hours_Granted*hours_Rate}</div>}

              </Table.Cell>
              }

              {decline_Reason && <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {decline_Reason}
              </Table.Cell>
              }
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

    </div>
  );




};

export default BidHistoryPage;
