import type { FC } from 'react';

import { useState } from 'react';

import { Button,  Label, TextInput, } from '../../lib';
import apiServices  from '../../services/apiServices';
import {  HiInformationCircle } from 'react-icons/hi';
import { Spinner,Alert } from '../../lib';
import { useNavigate } from "react-router-dom";


type Props = {
    isLoggedIn: boolean;
    setLoggedIn: (val: boolean) => void;
   
  };

const LoginPage:  FC<Props> = ({
    isLoggedIn,
    setLoggedIn,
   
  }) =>
{
const [username, setuserName] = useState<string>("");
const [password, setpassword] = useState<string>("");
const [loginfailed, setloginfailed] = useState<boolean>(false);
const [loading, setloading] = useState<boolean>(false);

isLoggedIn;

let navigate = useNavigate();

function handleLogin() {
  setloading(true);

    let svc = new apiServices();

        svc.login(username,password).then(()=>{
            setLoggedIn(true);
            setloading(false);
            navigate("/");
           
    
    }).catch((e)=>{
        console.log(e);
        setloading(false);
        setLoggedIn(false);
        setloginfailed(true);
        
        
    })
}


function handleRegister()
{
    navigate("/register");
    
    
}

      
 return (
  <div className="mx-auto flex max-w-4xl flex-col gap-8 dark:text-white">
             <span className="text-2xl font-bold">Login</span>
        <form className="flex flex-col gap-4" >
        
          <div>
            <div className="mb-2 block">
              <Label htmlFor="email1" value="Your email" />
            </div>
            <TextInput id="email1" type="email" placeholder="user@domain.com" required   value={username}
          onChange={e => setuserName(e.target.value)}/>
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="password1" value="Your password" />
            </div>
            <TextInput id="password1" type="password" required value={password}
          onChange={e => setpassword(e.target.value)}/>
          </div>
          
          {!loading && <Button onClick={handleLogin} >Login</Button>}

          {loading && <Spinner
    color="success"
   
  />}
          <Button onClick={handleRegister}>Register (Or Forgot)</Button>
        


          {loginfailed &&  <Alert color="failure" icon={HiInformationCircle}>
        Login Failed
      </Alert>}
        </form>
        </div>
        
        
      );
    
};

export default LoginPage;
