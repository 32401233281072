
import './formData.css'

import { ReactNode } from "react";
import { BidDisplay } from "../../../services/dtos";

interface Props {
    bid?: BidDisplay,
    children?: ReactNode
    // any props that come into the component
}

const FormBData = ({ children, ...props }: Props) => {
    return (
        <div id="form-data-wrapper">
            <div id="text">
                <div style={{ display: "flex", flexDirection: "column", paddingBottom: '20px' }}>
                    <span className="field">Project Name - Type:</span>
                    <span className="value"> {props.bid.project_Name}  - {props.bid.project_Type}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", paddingBottom: '20px' }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className="field">Trust Project Address:</span>
                        <span className="value"> {props.bid.project_Address}</span>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className="field">City:</span>
                        <span className="value"> {props.bid.project_City}</span>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className="field">County:</span>
                        <span className="value"> {props.bid.project_County}</span>
                    </div>

                </div>
                <div style={{ display: "flex", flexDirection: "row", paddingBottom: '20px', width: '50%', margin: 'auto' }}>
                    <div>
                        <span className="field">Bid Date:</span>
                        <span className="value"> {props.bid.bid_Date}</span>
                    </div>

                    <div>
                        <span className="field">Bid Time:</span>
                        <span className="value"> {props.bid.bid_Time}</span>
                    </div>
                </div>
            </div>

            <div id="table-wrapper">
                <table>
                    <thead>
                    <tr>
                        <th>Amount of Assistance</th>
                        <th>Maximum Number of Hours Approved</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>${props.bid.hours_Rate}/hr</td>
                        <td>{props.bid.hours_Granted} hours</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FormBData;