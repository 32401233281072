import type { FC } from 'react';

import { useState ,useEffect, useRef} from 'react';

import { Button, ToggleSwitch, Toast ,Table,Tooltip,Select,Checkbox,TextInput} from '../../lib';
import {  HiInformationCircle,HiCheck } from 'react-icons/hi';
import { Alert } from '../../lib';
import { useNavigate,useParams  } from "react-router-dom";
//import Datepicker from "flowbite-datepicker/Datepicker";
import { Spinner } from '../../lib';
import { setDefaultResultOrder } from 'dns/promises';
import bidServices from '../../services/bidServices';
import apiServices from '../../services/apiServices';

import {BidDisplay} from '../../services/dtos';
import { isNumberObject } from 'util/types';

const SubmiFormCPage: FC = () =>
{

  const { bidId } = useParams()


  const [yourBidAmt, setyourBidAmt] = useState<number>(0);

  const [yourBidStatus, setyourBidStatus] = useState<string>("");



  
  const [contractor1, setcontractor1] = useState<string>("");
  const [contractor1BidAmt, setcontractor1BidAmt] = useState<string>("");
  
  const [contractor2, setcontractor2] = useState<string>("");
  const [contractor2BidAmt, setcontractor2BidAmt] = useState<string>("");

const [contractor3, setcontractor3] = useState<string>("");
const [contractor3BidAmt, setcontractor3BidAmt] = useState<string>("");

const [applicant_signature, setapplicant_signature] = useState<string>();


const [updateFailed, setupdateFailed] = useState<boolean>(false);

const [errorMessage, setErrorMessage] = useState<string>("");
const [updateOk, setupdateOK] = useState<boolean>(false);
const [loading, setLoading] = useState<boolean>(false);

const [projectData, setProjectData] = useState<BidDisplay>();
const [failProject, setfailProject] = useState<boolean>(false);


const messagesEndRef = useRef(null)
const scrollToBottom = () => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
}

//const [toastMessage, setToastMessage] = useState<boolean>(false);


let uSvc= new apiServices();

let isAdmin = uSvc.hasHole("Admin");

useEffect(() => {
  setLoading(true);

 
  document.title = window.location.hostname.endsWith(".ieorgtrust.com") ? "IEOT Portal" : window.location.hostname.endsWith("nworgtrust.com") ? "NWOT Portal" : "WEQ" + window.location.hostname

  let svc = new bidServices();
 
 

  svc.getBids(parseInt(bidId)).then((BidList)=>
  {
    var bid = BidList[0];

    setProjectData(bid);
    setyourBidStatus(bid.bid_status);
    setyourBidAmt(bid.total_Bid);
    setcontractor1(bid.other_contractor_1);
    setcontractor1BidAmt(bid.other_contractor_1_bid.toString());

    setcontractor2(bid.other_contractor_2);
    setcontractor2BidAmt(bid.other_contractor_2_bid.toString());

    
    setcontractor3(bid.other_contractor_3);
    setcontractor3BidAmt(bid.other_contractor_3_bid.toString());

      setLoading(false);
    
      
  }
  ).catch((e)=>{
      console.log(e);
      setLoading(false);
  //  if(e.type=="RefreshTokenException" || e.responseStatus.errorCode == "403" || e.responseStatus.errorCode == "401")
    {
      navigate("/login");

    }
  });
  
}, []);



let navigate = useNavigate();
navigate;
setupdateFailed;




const updateBidAmt = (bid_amt : string)=>
{
  
if(!bid_amt)
{
  setyourBidAmt(0);
  
}

  setyourBidAmt(parseInt(bid_amt.replace(/\D/g,'')));

}

const updateOrApprove = (admin_approved : boolean) =>
{

  
  setLoading(true);
  
    setupdateFailed(false);
    setupdateOK(true);
    setErrorMessage("");
    

  let svc=new bidServices();
  svc.updateBid(failProject,admin_approved, projectData.bid_Id, yourBidAmt, yourBidStatus, contractor1, contractor1BidAmt, contractor2, contractor2BidAmt, contractor3, contractor3BidAmt

  ).then((res)=>{ 
   
    navigate("/");
    
    setLoading(false);
    setupdateFailed(false);
    setupdateOK(true);
    
    
  }).catch((e: any) => {
    setupdateOK(false);
    console.log(e);
    setLoading(false);
    var message = e.responseStatus.message ? e.responseStatus.message : JSON.stringify(e);

    setErrorMessage("Error updating bid - " + message);
    setupdateFailed(true);
    scrollToBottom(); 
  });
  
 

}


      
 return (
  <div className="mx-auto flex max-w-4xl flex-col gap-8 dark:text-white">

  <span className="text-2xl font-bold">Update Subsidy Status ("Form C")</span>

        { <form className="flex flex-col gap-4" >
        

        <Table>
  <Table.Head>
    <Table.HeadCell>
      Project Name / #
    </Table.HeadCell>
    <Table.HeadCell>
      Type
    </Table.HeadCell>
    <Table.HeadCell>
      City
    </Table.HeadCell>
    <Table.HeadCell>
      Bid Date
    </Table.HeadCell>
    <Table.HeadCell>
      Submitted By
    </Table.HeadCell>
    
  </Table.Head>
  { projectData && <Table.Body className="divide-y">
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
       {projectData.project_Name} #{projectData.project_Reference_Number}</Table.Cell>
    
     <Table.Cell> {projectData.project_Type}  
      </Table.Cell>

     <Table.Cell> {projectData.project_City} </Table.Cell> 

  
 <Table.Cell> {projectData.bid_Date} </Table.Cell>
    
      
    <Table.Cell> <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href={"mailto:" + projectData.contact_email}>{projectData.contact_email} </a>
      </Table.Cell>    
      
      </Table.Row>
     </Table.Body>}
  
  
</Table>


<Table>
  <Table.Head>
    <Table.HeadCell>
    Project Status
   
    </Table.HeadCell>
    <Table.HeadCell>
         Your Bid Amount
   
    </Table.HeadCell>
    </Table.Head>
   
  <Table.Body className="divide-y">
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">

      {yourBidStatus.indexOf("Accepted")>0 && yourBidStatus } 
      {yourBidStatus.indexOf("Accepted")<=0 &&
      <Select
      
    disabled={(!isAdmin && yourBidStatus.indexOf("Accepted")>0) ||  yourBidStatus.indexOf("Declined")==0}
    required={true}
    value={yourBidStatus}
    onChange={e => setyourBidStatus(e.target.value)}
  ><option value="Approved - Awaiting Results">
  Result of bid is unknown at this time - leave funds pending.
  </option>
    <option  value="Awarded">
    Our company has been awarded this project.
    </option>
    
    <option value="Lost">
    Our company lost this project - return funds to pool.
    </option>

    {yourBidStatus.indexOf("Approved")>0 && <option value="Awarded - Accepted">
    Our company has been awarded this project and funds have been approved
    </option>}

    {yourBidStatus.indexOf("Approved")>0 && <option value="Lost - Accepted">
    Our company lost this project - funds returned to pool.
    </option>
  }
    
    {yourBidStatus.indexOf("Declined")==0 && <option value="Declined">
    Assistance Declined
    </option>

    }

    
</Select>
}
      </Table.Cell>
      <Table.Cell>
      <TextInput
    id="yourbidamt"
    value={isNaN(Number(yourBidAmt)) ? "" : Number(yourBidAmt).toLocaleString()}
    onChange={e => updateBidAmt(e.target.value)} 
    placeholder="0"
    required={true}
    addon="$"
  />
      </Table.Cell>
      
      </Table.Row>
     </Table.Body>
</Table>



<Table>
  <Table.Head>
    <Table.HeadCell>
      Other Contractor
    </Table.HeadCell>
    <Table.HeadCell>
      Other Contractor Bid Amt
    </Table.HeadCell>
    </Table.Head>
   
  <Table.Body className="divide-y">
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
      <input placeholder="" value={contractor1} onChange={e => setcontractor1(e.target.value)}type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />

      </Table.Cell>
      <Table.Cell>
   
      <TextInput
    id="contractor1BidAmt"
    value={contractor1BidAmt} 
    onChange={e => setcontractor1BidAmt(e.target.value)} 
    placeholder="0"
    required={true}
    addon="$"
  />
    
      </Table.Cell>
      
      </Table.Row>
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
      <input placeholder="" value={contractor2} onChange={e => setcontractor2(e.target.value)}type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />

      </Table.Cell>
      <Table.Cell>
   
      <TextInput
    id="contractor2BidAmt"
    value={contractor2BidAmt} 
    onChange={e => setcontractor2BidAmt(e.target.value)} 
    placeholder="0"
    required={true}
    addon="$"
  />
      </Table.Cell>
      
      </Table.Row>
    
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
      <input placeholder="" value={contractor3} onChange={e => setcontractor3(e.target.value)}type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />

      </Table.Cell>
      <Table.Cell>
   
      <TextInput
    id="contractor3BidAmt"
    value={contractor3BidAmt} 
    onChange={e => setcontractor3BidAmt(e.target.value)} 
    placeholder="0"
    required={true}
    addon="$"
  />
      </Table.Cell>
      
      </Table.Row>
    
     </Table.Body>
</Table>

          {!isAdmin && <Button onClick={() => updateOrApprove(false)} >Send Update</Button>}
          
          {isAdmin &&  yourBidStatus=="Lost" && <ToggleSwitch
                checked={failProject}
                disabled={false}
                label={failProject ? "Fail project" : "Don't fail project - awaiting other bids"}
                onChange={() => setfailProject(!failProject)}
              />}

          {isAdmin && <Button onClick={() => updateOrApprove(true)} >Approve Form C {failProject ? " and fail project" : ""}</Button>}
        
        

          {updateFailed &&  <Alert color="failure" icon={HiInformationCircle}>
        {errorMessage}
        
      </Alert>}
      <div ref={messagesEndRef} />
      {loading && <Spinner
    color="success"
   
  />}
  {updateOk &&
      <Toast>
    <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
      <HiCheck className="h-5 w-5" />
    </div>
    <div className="ml-3 text-sm font-normal">
      Form C Update Sucessful
    </div>
    <Toast.Toggle />
  </Toast>}

        </form>}
        </div>
        
        
      );
    
};

export default SubmiFormCPage;
