//import { createContext, FC, useContext} from 'react';
import {  FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Suspense, useRef, useState,useEffect } from 'react';
//import {HiMenuAlt1} from 'react-icons/hi';
import {  Route, Routes } from 'react-router-dom';
import {  Navbar, Spinner ,DarkThemeToggle} from '../lib';
import LoginPage from './pages/LoginPage';

import apiServices  from './../services/apiServices';

import RegisterPage from './pages/RegisterPage';
import BidHistoryPage from './pages/BidHistoryPage';
import SubmitBidFormAPage from './pages/SubmitBidFormA';
import SubmitFormC from './pages/SubmitFormC';
import FormB from './pages/formb/FormB';
import FeedbackPage from './pages/FeedbackPage';
import { useNavigate } from "react-router-dom";
//import { useNavbarContext } from '../lib/components/Navbar/NavbarContext';
export const Root: FC = () => {

  //const [collapsed, setCollapsed] = useState(true);
  const [loggedIn,setLoggedIn] = useState(false);
  const [navbarCollapse,setnavbarCollapse] = useState(false);


  //const context = createContext({navBorOpen:navbarCollapse});


  const mainRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  //document.documentElement.classList.remove('dark');
  
  let navigate = useNavigate();

  const handleNavBarClick = () => {
    setnavbarCollapse(!navbarCollapse);
  };

  let logout = function () {
    let svc = new apiServices();
    
    svc.logout();
  
    navigate("/login");

    
  }


  
  useEffect(() => {
    let svc = new apiServices();
    setLoggedIn(svc.isloggedin);
  });



  

  //setLoggedIn(!svc.isloggedin());


  return (
    <div className="flex h-screen w-full flex-col  bg-white dark:bg-gray-700 overflow-hidden">

    {!location.pathname.toLocaleLowerCase().startsWith("/formb") &&  <Navbar  className="border-b" fluid>
            
      <Navbar.Brand >
       
      <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{ (window.location.hostname.endsWith(".ieorgtrust.com") ? "Inland Empire Organizational Trust" : window.location.hostname.endsWith("nworgtrust.com") ? "Northwest Organizational Trust" : window.location.hostname) }
     
      </div>
      </Navbar.Brand>
      <Navbar.Toggle  />
    
    
      <Navbar.Collapse>
          



    {!loggedIn  && <Navbar.Link
      href="/login"
      active={true}
    > 
      Login
    </Navbar.Link>}
    {!loggedIn  && <Navbar.Link
      href="/register"
      active={true}
    > 
      Register
    </Navbar.Link>}

    {loggedIn  && <Navbar.Link href="/" onClick={handleNavBarClick}>
      Subsidy History
    </Navbar.Link>}

   
    {loggedIn  && <Navbar.Link href="/feedback">
      Feedback
    </Navbar.Link>}

    <Navbar.Link href="/login" onClick={logout}>
      Logout
    </Navbar.Link>
    <DarkThemeToggle />
  
  </Navbar.Collapse>

  
</Navbar>
}
<div className="flex h-screen w-full flex-col overflow-hidden">
       
        <main className="flex-1 overflow-auto p-4" ref={mainRef}>
          <Suspense
            fallback={
              <div className="flex h-full items-center justify-center">
                <Spinner />
              </div>
            }
          >
            <Routes>
           

              <Route key="/login" path="/login" element={<LoginPage isLoggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
              <Route key="/register" path="/register"    element={<RegisterPage isLoggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
              { <Route key="/feedback" path="/feedback"    element={<FeedbackPage/>} />}
              { <Route  path="/FormA/:bidId"    element={<SubmitBidFormAPage/>} />}
              { <Route  path="/FormA/"    element={<SubmitBidFormAPage/>} />}
             
              { <Route key="/submitFormC" path="/submitFormC/:bidId"    element={<SubmitFormC/>} />}    
              { <Route key="/" path="/"    element={<BidHistoryPage isLoggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />}
              { <Route key="/bidhist" path="/bidist"    element={<BidHistoryPage isLoggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />}
              { <Route key="/formb" path="/formb/:bidId"    element={<FormB/>} />}
              
            
            </Routes>
          </Suspense>
        </main>
      </div>
     </div>
  );
 
 };
