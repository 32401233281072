import type { FC } from 'react';

import { useState ,useEffect} from 'react';

import { Button,  Toast } from '../../lib';
import {  HiInformationCircle,HiCheck } from 'react-icons/hi';
import { Alert } from '../../lib';
import { useNavigate } from "react-router-dom";

import { Spinner } from '../../lib';
const FeedbackPage: FC = () =>
{

const [feedback, setFeedback] = useState<string>("");
const [updateFailed, setupdateFailed] = useState<boolean>(false);

const [errorMessage, setErrorMessage] = useState<string>("");
const [updateOk, setupdateOK] = useState<boolean>(false);
const [loading, setloading] = useState<boolean>(false);


//const [toastMessage, setToastMessage] = useState<boolean>(false);

useEffect;

let navigate = useNavigate();
navigate;
setupdateFailed;






const handleUpdate = () =>
{

  
  setloading(true);
  setloading(false);
    setupdateFailed(false);
    setupdateOK(true);
    setErrorMessage("");
/*
  let svc=new memberInfoServices();
  svc.updateMemberFeedback(feedback).then(()=>{ 
   
    setloading(false);
    setupdateFailed(false);
    setupdateOK(true);
    
  }).catch((e: any) => {
    console.log(e);
    setloading(false);
    setErrorMessage("Error updating your information");
    setupdateFailed(true);
  });
  
  */
 

}




      
 return (
  <div className="mx-auto flex max-w-4xl flex-col gap-8 dark:text-white">

  <span className="text-2xl font-bold">Feedback for this site</span>

        { <form className="flex flex-col gap-4" >
        
        
      <div>
          
            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
            <textarea id="message"  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a comment..." required   value={feedback}
           onChange={e => setFeedback(e.target.value)}></textarea>

     
      </div>
    
          { <Button onClick={handleUpdate} >Send Feedback</Button>}
        

          {updateFailed &&  <Alert color="failure" icon={HiInformationCircle}>
        {errorMessage}
      </Alert>}

      {loading && <Spinner
    color="success"
   
  />}
  {updateOk &&
      <Toast>
    <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
      <HiCheck className="h-5 w-5" />
    </div>
    <div className="ml-3 text-sm font-normal">
      Thank you for your feedback.
    </div>
    <Toast.Toggle />
  </Toast>}

        </form>}
        </div>
        
        
      );
    
};

export default FeedbackPage;
