import apiServices from './apiServices';
import { getGCsReq, AddGCReq,SubsidyApproveOrDeny, BidSubmission, GetBidsReq,FormCBidUpdate, mergeBidsRequests }  from './dtos';



class bidServices  {
    

     baseSvc = new apiServices();
     client = this.baseSvc.client;

   
    constructor() {
     
      }


      getGCs()
      {
        const req = new getGCsReq();
        return this.client.get(req);
        
      }

      addGC(employer_Name:string)
      {
        const req = new AddGCReq();
        req.employer_Name = employer_Name;
        return this.client.post(req);

      }
   
      
      mergeBidProjects(bids:Array<number>, project_id :number)
      {
        const req = new mergeBidsRequests();
        req.bid_Ids = bids;
        req.project_Id = project_id;
        return this.client.put(req);

      }
   

      
      updateApproval(failProject: boolean, Bid_Id:number, 
        Decline_Reason : string, 
        Hours_Granted : number,
        Hours_rate : number
      )
      {
        const request = new SubsidyApproveOrDeny() ;
        request.bid_Id = Bid_Id;
        request.decline_Reason = Decline_Reason;
        request.hours_Granted = Hours_Granted;
        request.hours_rate = Hours_rate;
        request.fail_project = failProject;

        return this.client.put(request);



      }

      
      updateBid(failProject:boolean, approved_by_admin:boolean,Bid_Id:number, yourBidAmt:number, yourBidStatus:string, 
        contractor1:string,contractor1BidAmt:string,
        contractor2:string,contractor2BidAmt:string,
        contractor3:string,contractor3BidAmt:string
      )
      {
        const request = new FormCBidUpdate() ;
        request.approved_by_admin = approved_by_admin;
        request.bid_Id = Bid_Id;
        request.fail_project = approved_by_admin && failProject;
        request.my_bid_amt = yourBidAmt;
        request.my_bid_status = yourBidStatus;
        request.other_contractor_1 = contractor1;
        contractor1BidAmt = contractor1BidAmt.replace(/\D/g,'');
        contractor2BidAmt = contractor2BidAmt.replace(/\D/g,'');
        contractor3BidAmt = contractor3BidAmt.replace(/\D/g,'');

        if(!isNaN(+contractor1BidAmt))
          request.other_contractor_1_bid = parseFloat(contractor1BidAmt);
        request.other_contractor_2 = contractor2;
        if(!isNaN(+contractor2BidAmt))
          request.other_contractor_2_bid = parseFloat(contractor2BidAmt);
        request.other_contractor_3 = contractor3;
        if(!isNaN(+contractor3BidAmt))
          request.other_contractor_3_bid = parseFloat(contractor3BidAmt);
        

        return this.client.put(request);



      }

      submitBid(bid_id:string,applicant_signature:string,bid_Date:string,contact:string,contact_email:string,
        contact_phone:string, field_hours:number,less_than_10k_ft2:boolean, prevailing_wage:boolean,
        shop_hours:number, project_Address:string, project_City:string, project_County:string,
        project_Name:string, project_Type:string,Project_Name:string,
        Field_Hours:number,Fab_Contractor:string,
        Engineers:string,Equipment_Suppliers:string,Competition_Reason:string,
        Competition:string,Bid_Time: string,
        GcEmployerName:string
       

         )
         
        
      {
        const request = new BidSubmission();
        request.gcEmployerName = GcEmployerName;
        request.applicant_signature=applicant_signature;
        request.bid_Date = bid_Date;
      //  request.bid_ = bid_Date;
        request.contact = contact;
        request.contact_email = contact_email;
        request.contact_phone = contact_phone;
        request.field_Hours = field_hours;
        request.less_than_10k_ft2 = less_than_10k_ft2;
        request.prevailing_wage = prevailing_wage;
        request.shop_Hours = shop_hours;
        request.project_Address = project_Address;
        request.project_City = project_City;
        request.project_County = project_County;
        request.project_Name = project_Name;
        request.project_Type = project_Type;
        request.applicant_signature = applicant_signature;
        request.project_Name = Project_Name;
        request.field_Hours =Field_Hours;
        request.fab_Contractor = Fab_Contractor;
        request.engineers =Engineers;
        request.equipment_Suppliers = Equipment_Suppliers;
        request.competition_Reason = Competition_Reason;
        request.competition = Competition;
        request.bid_Time = Bid_Time;
        
        if(bid_id && parseInt(bid_id)>0)
        {
          
          request.bid_id = parseInt(bid_id);

          return this.client.put(request);
        }
        else
        {
          return this.client.post(request);
        }
        


      }
      

    getBids(bidId : number)
    {
        
        
        const request = new GetBidsReq();
        if(bidId && bidId>0)
        {
          request.bidId = bidId;
        }

        return this.client.get(request);


    }
    

}




export default bidServices;
